// UI Colors
$primary-color  : #204788;
$secondary-color: #88BFFF;
$link-color     : #1177bc;
$very-light-gray: #eeeeee;
$light-gray     : #cacaca;
$medium-gray    : #a3a3a3;
$dark-gray      : #8a8a8a;
$black          : #000000;
$white          : #ffffff;
$green          : #00bda0;
$light-green    : #e2faf7;
$red            : #ff263e;
$pink           : #fc74cd;
$orange         : #ffaa55;
$cyan           : #00bcd4;


// Palette colors for light mode
$theme-light-background-color: #fff;
$theme-light-grey-light      : #8f8f8f;
$theme-light-grey-dark       : #212121;

// Dark mode
$dark-mode-background-color: #546e7a;

// Update Bulma's global variables
$family-sans-serif: 'Nunito',
sans-serif;
$grey-dark         : $theme-light-grey-dark;
$grey-light        : $theme-light-grey-light;
$primary           : $cyan;
$link              : $cyan;
$widescreen-enabled: true;
$fullhd-enabled    : true;

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color  : transparent;
$input-shadow        : none;

@function smart-scale($color, $scale: 5%, $threshold: 40%) {
    @if lightness($color)>$threshold {
        $scale: -$scale;
    }

    @return scale-color($color, $lightness: $scale);
}
