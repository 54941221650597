@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:100,300,400,700');

// Import custom config
@import './style/theme.scss';

// Import only what you need from Bulma
@import './style/bulma.scss';

// Import animations
@import './style/animations.scss';

// CSS Fixes
:focus {
    outline: 0;
}

// * {
//     transition: color ease-in 200ms, fill ease-in 200ms, background-color ease-in 200ms, border-color ease-in 200ms;
// }

#root {
    min-height: 100vh;
}

// Generic style
h2 {
    font-size: 167%;

    &.breadCrumb {
        min-height: 36px;
    }
}

h3 {
    font-size: 117%;
}

.box {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Table
.table {
    tbody {
        tr {
            // Rounded corners
            &:first-child td:first-child {
                border-radius: 10px 0 0 0;
            }
            &:first-child td:last-child {
                border-radius: 0 10px 0 0;
            }
            &:last-child td:first-child {
                border-radius: 0 0 0 10px;
            }
            &:last-child td:last-child {
                border-radius: 0 0 10px 0;
            }

            // Cell
            td {
                transition: all 400ms ease-in-out;
                padding-top: 1rem;
                padding-bottom: 1rem;

                div.label {
                    font-weight: 100;
                    color: $grey-light;
                    font-size: 90%;
                }

                div.data {
                    font-weight: 400;
                    color: $grey-dark;
                    &.bold {
                        font-weight: 700;
                    }
                }

                &.is-text-align-right .label,
                &.is-text-align-right .data {
                    text-align: right;
                }

                .TokenIcon {
                    display: flex;
                    margin-right: 0.3em;
                }
            }

            &:hover td {
                background-color: #eceff1;
                cursor: pointer;
            }
        }
    }
}

span.unit {
    font-weight: 100;
    color: $theme-light-grey-light;
    font-size: 80%;
}

td.separator,
th.separator {
    display: table-cell;
    padding-left: 3rem;
    position: relative;

    // border-right: 1px solid black;
    &:after {
        content: ' ';
        width: 1px;
        height: 50%;
        background: #f3f3f3;
        display: block;
        position: absolute;
        // margin-left       : 120%;
        top: 25%;
        left: 1rem;
    }
}

// Pull to refresh
.ptr__pull-down {
    text-align: center;
    font-size: 140%;
}

// Bulma hacks
@media only screen and (max-width: 768px) {
    body {
        font-size: 75%;
    }

    .container.is-fluid {
        padding-left: 1em;
        padding-right: 1em;
    }

    .box {
        padding: 0.75em 0.75em;
    }
}

// Dark mode
html.theme-dark {
    background-color: $dark-mode-background-color;

    body {
        color: $white;
    }

    span.unit {
        color: $light-gray;
    }

    .box {
        background-color: smart-scale($dark-mode-background-color, 20%);
        color: $white;
    }

    a:hover {
        color: smart-scale($link, -20%);
    }

    .field label.label {
        color: $white;
    }

    .input,
    .textarea,
    .select select {
        background-color: smart-scale($dark-mode-background-color, 30%);
        color: $white;
    }

    .table {
        background-color: transparent;
        color: $white;

        tbody,
        thead {
            td,
            th {
                color: $white;
                border-color: smart-scale($dark-gray, 50%);
                div.label {
                    color: $light-gray;
                }
                div.data {
                    color: $white;
                }
            }

            td.separator::after,
            th.separator::after {
                background-color: smart-scale($dark-gray, 50%);
            }

            tr {
                &:hover td {
                    background-color: smart-scale($dark-mode-background-color, 0%);
                }
            }
        }
    }
}
