@import '../../style/theme.scss';

.ContentLoader {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $theme-light-background-color;
    z-index: 1000;
    text-align: center;

    .hero {
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .logo {
        display: block;
        transition: all 400ms ease-in-out;
        margin: 0 0 2rem 0;
        width: 100px;
        height: 100px;
        background: rgb(0, 188, 212);
        mask-image: url(/logo.svg);
        mask-size: cover;

        &.animated {
            animation: spin 20s infinite linear;
            background: $cyan;
        }
    }
}

// DARK MODE
.theme-dark .ContentLoader {
    background: smart-scale($dark-mode-background-color, 10%);
    color: $white;
    fill: $white;
}
